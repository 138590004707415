import router from './router';
// Create a Sentry-compatible router wrapper
const sentryRouterWrapper = {
    onError(callback) {
        // Implement error handling logic
        if (typeof window !== 'undefined') {
            window.addEventListener('error', callback);
            return () => window.removeEventListener('error', callback);
        }
        return () => {};
    },

    beforeEach(callback) {
        // Implement navigation guard logic
        const originalPush = router.push;
        router.push = function(params, dontReload = false) {
            callback?.();
            return originalPush.call(this, params, dontReload);
        };
        return () => { router.push = originalPush; };
    },

    afterEach(callback) {
        // Implement after navigation logic
        if (typeof window !== 'undefined') {
            window.addEventListener('popstate', callback);
            return () => window.removeEventListener('popstate', callback);
        }
        return () => {};
    },

    getCurrentRoute() {
        return {
            name: 'current',
            path: typeof window !== 'undefined' ? window.location.pathname : '',
            params: router.route.params || {},
            query: router.route.query || {}
        };
    }
};

export default sentryRouterWrapper;